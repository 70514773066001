export default {
  apiBaseUrl: process.env.REACT_APP_API_URL,
  socketUrl: process.env.REACT_APP_SOCKET_URL,
  adminApiURL: process.env.REACT_APP_ADMIN_API_URL,
  rbacApiBaseUrl: process.env.REACT_APP_RBAC_API_URL,
  debug: process.env.REACT_APP_API_DEBUG,
  apiUrl: process.env.REACT_APP_API_URL,
  s3URL: process.env.REACT_APP_S3_URL,
  tempBucket: process.env.REACT_APP_TEMP_BUCKET_NAME,
  permanentBucket: process.env.REACT_APP_PERM_BUCKET_NAME,
  profileImageUrl: process.env.REACT_APP_USER_PROFILE_URL,
  permFileURL: process.env.REACT_APP_PERM_FILE_URL,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  },
};
