import {
  Button, Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material'; import React from 'react';

const CancelConfirmDialoge = ({ showConfirmationDialog, handleCancelConfirmation, handleConfirmCancelConfirmation }) => (
  <div>
    <Dialog className="btn_style_prt" open={showConfirmationDialog}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have unsaved changes. Are you sure you want to cancel?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelConfirmation}>No</Button>
        <Button onClick={handleConfirmCancelConfirmation}>Yes</Button>
      </DialogActions>
    </Dialog>
  </div>
);

export default CancelConfirmDialoge;
