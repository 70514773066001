// eslint-disable-next-line import/prefer-default-export
export const validateFields = ({
  artifactFormData,
  draftFlag = false,
  artifactTypeName = 'PRO',
  hasLineItem = false,
  isEditable = false,
}) => {
  const errors = [];
  const urlRegex = /^(https:\/\/)([\da-z.-]+)\.([a-z.]{2,})([/\w.-]*)*(#.*)?$/i;

  if (draftFlag) {
    artifactFormData?.forEach((formElement) => {
      const error = {};
      if (formElement.name.trim() === '') {
        error.name = 'Please enter artifact name';
      }
      if (formElement.artifact_sub_category_id === '') {
        error.artifact_sub_category_id = 'Please select Subcategory';
      }
      errors.push(error);
    });
  } else {
    artifactFormData.forEach((formElement) => {
      const error = {};
      if (formElement.name.trim() === '') {
        error.name = 'Please enter artifact name';
      }
      if (formElement.artifact_sub_category_id === '') {
        error.artifact_sub_category_id = 'Please select Subcategory';
      }
      if ((hasLineItem && formElement.selectedlineItem === '')) {
        error.selectedlineItem = 'Please select line item';
      }
      if (artifactTypeName !== 'FB' && !formElement?.files?.length && !isEditable) {
        error.files = 'Please select file(s) to upload';
      }
      if (artifactTypeName !== 'FB') {
        if (formElement.description.trim() === '') {
          error.description = 'Please enter description';
        }
      }
      if (artifactTypeName !== 'FB' && formElement.file_path.length <= 0) {
        error.file_path = 'Please upload document ';
      }
      if ((formElement.project_name === '') && (artifactTypeName === 'PRO' || (artifactTypeName === 'SQU' && formElement.selectedlineItem === 'Project POC'))) {
        error.project_name = 'Please select project name';
      }
      if ((formElement?.project_manager?.length <= 0 || formElement?.project_manager_id === '') && (artifactTypeName === 'PRO')) {
        error.project_manager_id = 'Please select project manager name';
      }
      if ((formElement.from_date === '' || formElement.from_date === null) && (artifactTypeName === 'PRO')) {
        error.from_date = 'Please select start date';
      }
      if ((formElement.to_date === '' || formElement.to_date === null) && (artifactTypeName === 'PRO') && (formElement.project_working_status === false || (formElement.project_working_status) === 'false')) {
        error.to_date = 'Please select end date';
      }
      if ((formElement.url === '') && (artifactTypeName === 'SQU')) {
        error.url = 'Please enter URL';
      }
      if (!(urlRegex.test(formElement.url)) && (artifactTypeName === 'SQU')) {
        error.url = 'Please enter Valid URL';
      }
      if (Object.keys(error)?.length) { errors.push(error); }
    });
  }
  return errors;
};

