import styled from 'styled-components';

export const GrowthAppraisal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 64%; /* Ensure full width */
  @media (max-width: 1150px) {
    justify-content: left;
  }
`;

export const GrowthAppraisalContainer = styled.div`
  &.rank-list ul {
    display: flex;
    // max-width: 100%;
    padding: 0 30px;
    flex-direction: column;
    align-items: flex-start;
  }
  &.rank-list li p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 10px;
    color: #1d1d1d;
  }
  &.rank-list li span {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-right: 8px;
    color: rgb(123, 123, 123);
  }
`;
