/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Avatar,
  Grid,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  ListItemAvatar,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  TextField,
  CircularProgress,
  Button,
  Checkbox,
  MenuItem,
  Chip,
  Dialog,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TabContext } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import isEmpty, { getAuthHeader, getCurrentYear, isNil } from '../../../utils/utils';
import { formatArtifact, truncateText } from './helper';
import { defaultViewLevelColumn, status } from '../../../libs/Constant';
import { ArtifactWrapper } from '../Artifact.styled';
import { convertArrayOfObjectsToCsv } from '../../../utils/CsvHelper';
import { User } from '../../Dashboard/components/UserProfile/type';
import { RootState } from '../../../store/type';
import ImagePreview from './ImagePreview';

const ViewAllArtifact = () => {
  // eslint-disable-next-line no-unused-vars
  const { userId } = useParams();
  const user: User = useSelector((state: RootState) => state.user.employee);
  const fullName = `${user.firstName}_${user.lastName}`;
  const navigate = useNavigate();
  const [fileUrl, setfileUrl] = useState('');
  const [showBuArtifact, setshowBuArtifact] = useState(false);
  const [fileType, setfileType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState({});
  const [selectStatus, setSelectStatus] = React.useState<string[]>([]);
  const loggedUser = useSelector((state: any) => state.user.employee);
  const loggedInUserId = localStorage.getItem('user_id');
  const hasEditAccess = isNil(userId) ? true : userId === loggedInUserId;
  const currentYear = (useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear())?.toString();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(true);
  const [userFullName, setUserFullName] = useState<string>('');
  const col = ['name', 'combinedNames', 'approved_by'];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    // Filter data based on selected status and subcategory
    const currentCategory = category[selectedCategory];
    const currentSubCategory = currentCategory?.categoryDatas?.find((sub: any) => sub.subCategoryName === selectedSubcategory);
    if (currentSubCategory) {
      const data = currentSubCategory.subCategoryData.filter((item: any) => (selectStatus.length === 0 ? true : selectStatus.includes(item.approved)));
      // eslint-disable-next-line no-console
      console.log(data);
    }
  }, [selectStatus, selectedCategory, selectedSubcategory, category]);

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const fetchData = useCallback(async () => {
    try {
      const response: any = await CommHandler.request({
        url: `${config.apiBaseUrl}/users/${userId}`,
        method: 'GET',
        headers: getAuthHeader,
      });
      return response;
    } catch (err) {
      return false;
    }
  }, [userId]);

  useEffect(() => {
    const getUserData = async () => {
      const response = await fetchData();
      if (response && response.data) {
        setUserFullName(`${response.data.firstName}_${response.data.lastName}`);
      }
    };
    getUserData();
  }, [fetchData]);

  const fetchArtifactData = useCallback(async () => {
    setLoading(true);
    try {
      const groupingSuperUser = loggedUser?.isSuperAdmin ? 'grouping/superadmin' : 'grouping';
      const response: any = await Promise.all([
        CommHandler.request({
          url: `${config.adminApiURL}/artifact/category`,
          method: 'GET',
          params: { status: 1 },
          headers: getAuthHeader,
        }),
        CommHandler.request({
          url: `${config.apiBaseUrl}/artifact/${groupingSuperUser}?loggedInUserId=${loggedInUserId}&userId=${hasEditAccess ? loggedInUserId : userId}`,
          method: 'GET',
          params: {
            year: currentYear,
          },
          headers: getAuthHeader,
        }),
      ]);

      const data = await response;
      if (!isEmpty(data) && data?.[1]?.data?.length && data?.[0]?.data?.length) {
        const { resultObj: artifact }: any = await formatArtifact(data[1].data, data[0].data);

        const newList = data?.[0]?.data?.reduce((accu: any, item: any) => {
          const filterData = artifact[item.id];
          let totalSubcategoryLength = 0;
          const categoryDatas = filterData?.map((items: any) => {
            const filterCategory = data[1]?.data?.filter((key: any) => key.name === items.name && key.artifact_category_id === items.artifact_category_id);
            if (filterCategory.length) {
              return {
                subCategoryName: items.name,
                subCategoryData: filterCategory.reduce((pre: any, newData: any) => {
                  if (newData && newData?.data) {
                    totalSubcategoryLength += newData.data.length;
                  }
                  return [...pre, ...newData.data];
                }, []),
              };
            }
            return { subCategoryName: items.name };
          });
          // eslint-disable-next-line no-param-reassign
          accu[item?.id] = {
            artifactCatId: item.id,
            categoryName: item.name,
            categoryDatas,
            totalSubcategoryLength,
          };
          return accu;
        }, {});
        setCategory(newList);
        setCategoryData(Object.values(newList));
        type CategoryType = {
          artifactCatId?: number;
        };
        const firstCategory: CategoryType = Object.values(newList)?.[0];
        setSelectedCategory(firstCategory.artifactCatId);
      }
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error('Error fetching artifact data:', err);
      return false;
    }
  }, [loggedUser, loggedInUserId, hasEditAccess, userId, currentYear]);

  const fetchDataCallback = useCallback(fetchData, [fetchData]);

  useEffect(() => {
    fetchArtifactData();
    fetchDataCallback();
  }, [currentYear, fetchArtifactData, fetchDataCallback]);

  const handleCategoryChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedCategory(newValue);
    setSelectedSubcategory('');
    setCurrentPage(0);
    setRowsPerPage(10);
    setSelectStatus([]);
    setSearchQuery('');
  };

  const handleSubcategoryChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedSubcategory(newValue);
    setSelectStatus([]);
    setCurrentPage(0);
    setRowsPerPage(10);
    setSearchQuery('');
  };

  const handleStatusChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectStatus(selectedValues);
  };

  const downloadCsv = () => {
    const result = [];
    categoryData.forEach((i) => {
      const { categoryName, categoryDatas: catData } = i;
      const sortedCatData = catData?.sort((a: any, b: any) => a?.subCategoryName.localeCompare(b?.subCategoryName)) || [];
      sortedCatData.forEach((j) => {
        const { subCategoryName, subCategoryData } = j;
        subCategoryData.forEach((k) => {
          const {
            approverList, files, approved_by: approvedBy, description, achieved_points: acheivedPoint, name,
          } = k;
          const uniqueApproverList = approverList.filter((item, index, self) => index === self.findIndex((t) => t?.id === item?.id));
          const combinedNames = uniqueApproverList?.map((item: { name: any }) => item?.name).join(', ');
          const attachments = files?.map((file) => file.name)?.join(', ');
          result.push({
            Category: categoryName,
            'Sub Category': subCategoryName,
            'Artifact Name': name,
            'Approver List': combinedNames || '',
            'Approved By': approvedBy || '',
            Description: description.replace(/<[^>]+>/g, '') || '',
            Points: acheivedPoint || '',
            Attachments: attachments || '',
          });
        });
      });
    });

    const csvData = convertArrayOfObjectsToCsv(result, [
      { label: 'Category', key: 'Category' },
      { label: 'Sub Category', key: 'Sub Category' },
      { label: 'Artifact Name', key: 'Artifact Name' },
      { label: 'Approver List', key: 'Approver List' },
      { label: 'Approved By', key: 'Approved By' },
      { label: 'Description', key: 'Description' },
      { label: 'Points', key: 'Points' },
      { label: 'Attachments', key: 'Attachments' },
    ]);

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = userId ? `${userFullName ?? ''}_data.csv` : `${fullName ?? ''}_data.csv`;

    a.click();
  };

  const handleURLSelect = async (f: any) => {
    try {
      const response = await CommHandler.get(`${config.apiBaseUrl}/uploadFiles/get-signed-url`, {
        params: { key: f },
        headers: getAuthHeader,
      });
      const signedUrl = response;
      if (signedUrl) {
        const str = `${config.s3URL}${config.permanentBucket}/${f}`;
        const result = str?.substring(str.length - 3);
        setfileUrl(str);
        setfileType(result);
        setshowBuArtifact(true);
      } else {
        console.error('Signed URL is undefined');
      }
    } catch (error) {
      console.error('Error handling file select:', error.message);
    }
  };

  const currentCategory = category?.[selectedCategory];
  const currentSubCategorySort = currentCategory?.categoryDatas?.sort((a: any, b: any) => a?.subCategoryName.localeCompare(b?.subCategoryName)) || [];
  const currentSubCategory = currentSubCategorySort?.[0];
  const currentData = currentSubCategory?.subCategoryData || [];
  const currentTabData = currentSubCategorySort?.filter((subCategory: any) => selectedSubcategory && selectedSubcategory === subCategory?.subCategoryName)?.[0]?.subCategoryData || currentData;
  const currentMapTabData = currentTabData?.map((ival: any) => {
    const uniqueApproverList = ival?.approverList.filter((item, index, self) => index === self.findIndex((t) => t?.id === item?.id));
    const combinedNames = uniqueApproverList?.map((item: { name: any }) => item?.name).join(', ');
    return {
      ...ival,
      combinedNames,
    };
  });

  const data = currentMapTabData
    ?.filter((item) => col?.some((column) => item[column]?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())))
    ?.filter((item) => (selectStatus?.length ? selectStatus?.map((i) => i?.toLowerCase()).includes(item?.approved) : true));
  const pageData = data?.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
  useEffect(() => {
    if (currentSubCategory?.subCategoryName) {
      setSelectedSubcategory(currentSubCategory?.subCategoryName);
    }
    setSelectStatus([]);
  }, [selectedCategory, currentSubCategory?.subCategoryName, currentYear]);

  return (
    <>
      <ArtifactWrapper>
        <CardContent style={{ display: 'flex', alignItems: 'center', margin: '-20px' }}>
          <div style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} onKeyPress={(e: { key: string }) => e.key === 'Enter' && navigate(-1)} role="button" tabIndex={0}>
            <img src="/leftArrow.png" alt="left-arrow" width="16px" height="16px" style={{ marginRight: '12px', marginTop: '5px' }} />
          </div>
          <h2>Artifact Report</h2>
        </CardContent>
        {/* Loading Spinner */}
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress />
          </Box>
        ) : (
          // Subcategory Data Table
          <TabContext value={selectedCategory as number}>
            <Grid>
              <div className="artifact-main-table">
                <div className="employee-nav">
                  {/* Category Tabs */}
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '48px',
                    }}
                    sx={{ marginTop: '15px' }}
                  >
                    <Tabs value={selectedCategory} onChange={handleCategoryChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                      {categoryData?.map((Category: any) => (
                        <Tab
                          key={Category.artifactCatId}
                          className="live-rank-tab-container"
                          label={Category.categoryName.length > 15 ? `${Category.categoryName.substring(0, 15)}...` : `${Category.categoryName}`}
                          title={Category.categoryName.length > 15 ? Category.categoryName : undefined}
                          value={Category.artifactCatId}
                        />
                      ))}
                    </Tabs>
                    <Box
                      className="actions"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '16px',
                        marginBottom: '2px',
                      }}
                    >
                      <TextField
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '41px',
                            width: '240px',
                          },
                        }}
                        label={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SearchIcon sx={{ mr: 1, marginTop: '1px' }} />
                            Search
                          </div>
                        }
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                      />

                      <Box>
                        <FormControl sx={{ width: 150 }}>
                          <InputLabel id="demo-multiple-checkbox-label" sx={{ top: '-4px' }}>
                            Filter
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={selectStatus}
                            onChange={handleStatusChange}
                            input={<OutlinedInput label="FILTER" />}
                            // renderValue={(selected) => selected.length > 0 && <Chip label={`+${selected.length - 0}`} />}
                            renderValue={(selected) => {
                              if (selected.length === 1) {
                                return <Chip label={selected[0]} />;
                              } if (selected.length > 1) {
                                return <Chip label={`${selected[0]} +  ${selected.length - 1}`} />;
                              }
                              return null;
                            }}
                            MenuProps={MenuProps}
                            sx={{
                              '& .MuiSelect-select': {
                                padding: '11px 24px 11px 24px',
                                gap: '8px',
                                display: 'flex',
                                alignItems: 'center',
                              },
                            }}
                          >
                            {status?.map((Status) => (
                              <MenuItem key={Status} value={Status}>
                                <Checkbox checked={selectStatus.indexOf(Status) > -1} />
                                <ListItemText primary={Status} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box>
                        <div className="artifact-btn-space">
                          <Button
                            style={{
                              width: '139px',
                              height: '41px',
                              marginRight: '24px',
                              marginBottom: '21px',
                              marginLeft: '8px',
                              textTransform: 'none',
                            }}
                            variant="contained"
                            className="addArtifact-btn"
                            onClick={downloadCsv}
                          >
                            <div>
                              <DownloadIcon style={{ marginRight: '10px', marginTop: '4px' }} />
                            </div>
                            Download
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                  {/* Subcategory Tabs */}
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    sx={{ backgroundColor: '#EDF3FF' }}
                  >
                    <Tabs value={selectedSubcategory} onChange={handleSubcategoryChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                      {currentSubCategorySort?.map((subCategory: any) => (
                        <Tab
                          key={subCategory.subCategoryName}
                          className="live-rank-tab-container"
                          label={subCategory.subCategoryName.length > 15 ? `${subCategory.subCategoryName.substring(0, 15)}...` : `${subCategory.subCategoryName}`}
                          title={subCategory.subCategoryName.length > 15 ? subCategory.subCategoryName : undefined}
                          value={subCategory.subCategoryName}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </div>
                <div className="table-row-item-cell">
                  {defaultViewLevelColumn?.map((heading: string) => (
                    <Box key={heading} className="artifact-table-head">
                      {heading}
                    </Box>
                  ))}
                </div>
                {currentTabData && currentTabData.length > 0 && data?.length !== 0 ? (
                  pageData?.map((ival: any) => (
                    <div className="table-row-item">
                      <Box className="artifact-table-head">
                        <List>
                          <ListItem className="list-artifact">
                            <Tooltip title={ival.name} arrow>
                              <ListItemText className="listIem-text" primary={truncateText(ival.name, 40)} />
                            </Tooltip>
                            {' '}
                          </ListItem>
                        </List>
                      </Box>
                      <Box className="artifact-table-head">
                        <List>
                          <ListItem className="list-artifact">
                            <Tooltip title={ival?.combinedNames} arrow>
                              <ListItemText className="listIem-text" primary={truncateText(ival?.combinedNames, 40)} />
                            </Tooltip>
                          </ListItem>
                        </List>
                      </Box>
                      <Box className="artifact-table-head">
                        <List>
                          <ListItem className="list-artifact">
                            <ListItemText className="listIem-text" primary={ival.approved_by} />
                          </ListItem>
                        </List>
                      </Box>
                      <Box className="artifact-table-head">
                        <List>
                          <ListItem className="list-artifact">
                            {ival.approved === 'pending' && <div className="status-pending">Pending</div>}
                            {ival.approved === 'draft' && <div className="status-draft">Draft</div>}
                            {ival.approved === 'approved' && <div className="status-approved">Approved</div>}
                            {ival.approved === 'rejected' && <div className="status-rejected">Rejected</div>}
                          </ListItem>
                        </List>
                      </Box>
                      <Box className="artifact-table-head">
                        <List>
                          <ListItem
                            sx={{
                              width: '80px',
                              height: '40px',
                              backgroundColor: '#FDF5EB',
                              borderRadius: '5px',
                              padding: '8px 8px 8px 8px',
                              fontStyle: 'Inter',
                              fontWeight: '600',
                              fontSize: '16px',
                              lineHeight: '19.36px',
                              color: '#EDA13B',
                              gap: '8px',
                            }}
                          >
                            <ListItemAvatar sx={{ minWidth: '0px !important' }} />
                            <Avatar
                              src="/Page-1.png"
                              sx={{
                                width: '24px',
                                height: '24px',
                              }}
                            />
                            <ListItemText className="listIem-text" primary={ival.achieved_points} />
                          </ListItem>
                        </List>
                      </Box>
                      <Box
                        className="artifact-table-head-artifact"
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        {ival.file_path?.map((f, i) => (
                          <a
                            href={f}
                            onClick={(e) => {
                              e.preventDefault();
                              handleURLSelect(f);
                            }}
                          >
                            {`Attachment${i + 1}`}
                          </a>
                        ))}
                      </Box>
                    </div>
                  ))
                ) : (
                  <Box style={{ textAlign: 'center', fontWeight: 400 }}>No data to display</Box>
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center" paddingRight="12px">
                  <div className="pgntn_box">
                    <TablePagination component="div" count={data?.length} page={currentPage} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} rowsPerPageOptions={[10, 20, 30]} />
                  </div>
                </Box>
              </div>
            </Grid>
          </TabContext>
        )}
      </ArtifactWrapper>
      <Dialog
        open={showBuArtifact}
        onClose={() => setshowBuArtifact(false)}
        PaperProps={{
          style: { width: '80%', maxWidth: 'none' },
        }}
      >
        <ImagePreview type={fileType} url={fileUrl} setshowBuArtifact={setshowBuArtifact} />
      </Dialog>
    </>
  );
};

export default ViewAllArtifact;
