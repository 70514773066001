import Papa from 'papaparse';
import 'papaparse/papaparse.min.js';

export const convertArrayOfObjectsToCsv = (data: any[], columnMapping: { label: string; key: string }[]) => {
  const csvData = data.map((item) => {
    const csvRow: any = {};
    columnMapping.forEach((column) => {
      const keys = column.key.split('.');
      let value = item;
      keys.forEach((key) => {
        if (Array.isArray(value)) {
          value = value.map((v: any) => v[key]).join(', ');
        } else {
          value = value[key];
        }
      });
      csvRow[column.label] = value;
    });
    return csvRow;
  });

  const csvString = Papa.unparse(csvData);
  return csvString;
};
