/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
  Box, Divider, Chip, Paper, Typography, Grid, IconButton, Avatar, TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { getFormattedDate } from '../../../../utils/utils';
// import socket from '../../../../service/socketService';

const ChatMessages = ({
  chatMessages, editingMessageId, handleEditMessage, deleteChatMessage, handleConfirmEdit, editingMessageText, setEditingMessageText, handleCancelEdit, handleKeyPress,
}) => {
  const [isHovered, setIsHovered] = useState(null);
  const loggedInUserFirstName = localStorage.getItem('firstName');
  const loggedInUserLastName = localStorage.getItem('lastName');

  const handleMouseEnter = (id) => {
    setIsHovered(id);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  const categorizeMessagesByDate = () => {
    const categorizedMessages = {};
    chatMessages.forEach((msg) => {
      const messageDate = new Date(msg.sendAt);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const msgDateStr = messageDate.toDateString();
      const todayStr = today.toDateString();
      const yesterdayStr = yesterday.toDateString();

      if (msgDateStr === todayStr) {
        if (!categorizedMessages['Today']) {
          categorizedMessages['Today'] = [];
        }
        categorizedMessages['Today'].push(msg);
      } else if (msgDateStr === yesterdayStr) {
        if (!categorizedMessages['Yesterday']) {
          categorizedMessages['Yesterday'] = [];
        }
        categorizedMessages['Yesterday'].push(msg);
      } else {
        if (!categorizedMessages[msgDateStr]) {
          categorizedMessages[msgDateStr] = [];
        }
        categorizedMessages[msgDateStr].push(msg);
      }
    });
    return categorizedMessages;
  };

  const renderChatMessages = () => {
    const categorizedMessages = categorizeMessagesByDate();
    let previousSender = null;
    let previousDate = null;

    return Object.keys(categorizedMessages).map((date) => (
      <Box key={date} mb={2}>
        <Divider variant="middle">
          <Chip label={date} size="small" color="primary" variant="outlined" />
        </Divider>
        {categorizedMessages[date]?.map((msg) => {
          const isSender = msg?.sender === `${loggedInUserFirstName} ${loggedInUserLastName}`;
          const formattedDate = getFormattedDate(msg.sendAt);
          const shouldDisplayDate = previousSender !== msg?.sender || previousDate !== formattedDate;

          previousSender = msg.sender;
          previousDate = formattedDate;

          if (msg?.isDeleted) {
            return (
              <Box key={msg.id} display="flex" justifyContent={isSender ? 'flex-end' : 'flex-start'} mb={1} mt="10px" position="relative">
                <Paper
                  elevation={3}
                  style={{
                    padding: '8px',
                    maxWidth: '75%',
                    wordWrap: 'break-word',
                    backgroundColor: '#F5F5F5',
                    borderRadius: '20px',
                    visibility: 'visible',
                    position: 'relative',
                    marginBottom: '30px',
                  }}
                >
                  <Typography variant="body1" gutterBottom style={{ fontStyle: 'italic', color: '#AAA' }}>
                    message deleted by its author
                  </Typography>
                </Paper>
              </Box>
            );
          }

          return (
            <Box className="trial" key={msg?.id} display="flex" justifyContent={isSender ? 'flex-end' : 'flex-start'} mb={1} mt="10px" position="relative">
              {shouldDisplayDate && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    position: 'absolute',
                    top: '-18px',
                    right: isSender ? '8px' : 'auto',
                    left: !isSender ? '8px' : 'auto',
                  }}
                >
                  {formattedDate}
                  {' '}
                  {msg.isEdited && '(Edited)'}
                </Typography>
              )}

              <Paper
                elevation={3}
                style={{
                  padding: '8px',
                  maxWidth: '75%',
                  wordWrap: 'break-word',
                  backgroundColor: isSender ? '#DCF8C6' : '#FFF',
                  borderRadius: '18px',
                  visibility: 'visible',
                  position: 'relative',
                  marginBottom: '30px',
                }}
                onMouseEnter={() => handleMouseEnter(msg?.id)}
                onMouseLeave={() => handleMouseLeave()}
              >
                <Grid container alignItems="center" spacing={1}>
                  {!isSender && (
                    <Grid item>
                      <Avatar alt="Other User Avatar">{msg?.sender?.charAt(0)}</Avatar>
                    </Grid>
                  )}
                  <Grid item xs>
                    {editingMessageId === msg?.id ? (
                      <>
                        <TextField
                          value={editingMessageText}
                          onChange={(e) => setEditingMessageText(e.target.value)}
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={2}
                          autoFocus
                          onKeyPress={handleKeyPress}
                          sx={{
                            borderRadius: '10px',
                          }}
                          inputProps={{
                            defaultValue: editingMessageText,
                            ref: (input) => {
                              if (input) {
                                input.focus();
                                input.setSelectionRange(editingMessageText.length, editingMessageText.length);
                              }
                            },
                          }}
                        />
                        <Box display="flex" justifyContent="flex-end" mt={1}>
                          <IconButton size="small" color="secondary" onClick={handleCancelEdit}>
                            <CloseIcon />
                          </IconButton>
                          <IconButton size="small" color="primary" onClick={handleConfirmEdit}>
                            <CheckIcon />
                          </IconButton>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Typography variant="body1" gutterBottom>
                          {msg.message}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {msg.sender}
                          {' '}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Box
                    sx={{
                      position: 'absolute',
                      right: '8px',
                      visibility: isHovered === msg?.id ? 'visible' : 'hidden',
                      transition: 'visibility 0.2s',
                      marginBottom: '10px',
                      marginTop: '-80px',
                    }}
                  >
                    {isSender && editingMessageId !== msg?.id && (
                      <Grid item>
                        <Box bgcolor="#fff" p={0.5} borderRadius={2}>
                          <IconButton size="small" color="primary" disabled={msg.disableActions} onClick={() => handleEditMessage(msg)} sx={{ '&:hover': { backgroundColor: '#90CAF9' } }}>
                            <EditIcon />
                          </IconButton>
                          <IconButton size="small" color="secondary" disabled={msg.disableActions} onClick={() => deleteChatMessage(msg?.id)} sx={{ color: 'red', '&:hover': { backgroundColor: '#EF9A9A' } }}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </Paper>
            </Box>
          );
        })}
      </Box>
    ));
  };

  return <Box>{renderChatMessages()}</Box>;
};

export default ChatMessages;
