/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const SubmitButton = ({
  isLoading,
  disabledSubmit,
  submitEnabled,
  handleSubmit,
}) => (
  <>
    {isLoading || disabledSubmit ? (
      <div>
        <Button
          className="addArtifact-form-btn"
          variant="contained"
          disabled={isLoading || disabledSubmit}
          startIcon={
              isLoading && (
                <CircularProgress
                  size={20}
                  color="inherit"
                />
              )
            }
        >
          Submit
        </Button>
      </div>
    ) : (
      <div>
        <Button
          className={`addArtifact-form-btn ${
            !submitEnabled ? 'primary-color' : ''
          }`}
          variant="contained"
          disabled={submitEnabled}
          onClick={handleSubmit}
          startIcon={(
            <img
              src="/submit1.png"
              alt="submit button"
              width="15px"
              height="15px"
            />
            )}
        >
          Submit
        </Button>
      </div>
    )}
  </>
);

export default SubmitButton;
