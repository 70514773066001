import React, { useEffect, useState } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { UserProfileWrapper, UserProfileContainer, UserProfileContent } from './UserProfile.styled';
import { RootState } from '../../../../store/type';
import { User, UserData } from './type';
import { config } from '../../../../config';
import CommHandler from '../../../../utils/CommHandler';
import { getAuthHeader } from '../../../../utils/utils';

const CustomizedAvatar = styled(Avatar)`
    border: 2px solid transparent;
`;

const UserProfiles = (props:any) => {
  const [userData, setUserData] = useState({
    employeeID: '',
    firstName: '',
    lastName: '',
    email: '',
    personalDetails: {
      phoneNumber: '',
    },
    profile: {
      designation: '',
      department: '',
      imagePath: '',
    },
  });
  const { data, userProfileId } = props;
  const selectUser = (state: RootState) => state.user.employee;
  const user: User = useSelector(selectUser);
  const empId: any = user.profile;
  const userID = empId?.user_id;

  const [isPropData, setIsPropData] = useState(false);
  const imageUrlPrefix = `${config.profileImageUrl}/200x200/`;
  const altText = userData?.firstName && userData?.lastName ? `${userData?.firstName?.charAt(0).toUpperCase()}${userData?.lastName?.charAt(0).toUpperCase()}` : null;
  const searchUser = isPropData && data?.firstName && data?.lastName ? `${data.firstName?.charAt(0).toUpperCase()}${data.lastName?.charAt(0).toUpperCase()}` : null;

  useEffect(() => {
    if (data) {
      setIsPropData(true);
    }
  }, [data]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const fetchAndSetData = async () => {
      if (userProfileId || userID) {
        try {
          const idToUse = userProfileId || userID;
          const response: any = await CommHandler.request({
            url: `${config.apiBaseUrl}/users/${idToUse}`,
            method: 'GET',
            headers: getAuthHeader,
          });
          response.data.profile.imagePath = response.data.profile.imagePath || '';
          setUserData(response?.data);
          return response;
        } catch (err) {
          /* eslint-disable no-console */
          console.error('Error fetching artifact data:', err);
          /* eslint-enable no-console */
          return false;
        }
      } else {
        const newUser: UserData = {
          ...user,
          profile: {
            ...user.profile,
            imagePath: user?.profile?.imagePath || '',
          },
          personalDetails: {
            phoneNumber: undefined,
          },
        };
        setUserData(newUser);
      }
    };

    fetchAndSetData();
  }, [userProfileId, user, userID]);

  // const fetchData = useCallback(async () => {
  //   try {
  //     const response: any = await CommHandler.request({
  //       url: `${config.apiBaseUrl}/users/${userProfileId}`,
  //       method: 'GET',
  //     });
  //     response.data.profile.imagePath = response.data.profile.imagePath || '';
  //     setUserData(response?.data);
  //     return response;
  //   } catch (err) {
  //     console.error('Error fetching user data:', err.message);
  //     return false;
  //   } finally {
  //     // setLoading(false);
  //   }
  // }, [userProfileId]);

  // useEffect(() => {
  //   const fetchAndSetData = async () => {
  //     if (userProfileId) {
  //       await fetchData();
  //     } else {
  //       const newUser: UserData = {
  //         ...user,
  //         profile: {
  //           ...user.profile,
  //           imagePath: user?.profile?.imagePath || '',
  //         },
  //       };
  //       setUserData(newUser);
  //     }
  //   };

  //   fetchAndSetData();
  // }, [userProfileId, user, fetchData]);

  return (
    <UserProfileWrapper className="user-profile-wrapper" style={{ maxWidth: '30%' }}>
      {Object.keys(user).length ? (
        <UserProfileContainer className="user-profile-container">
          <CustomizedAvatar
            sx={{ width: 88, height: 88 }}
            src={data && data?.profile ? `${imageUrlPrefix}${data.profile?.imagePath}` : `${imageUrlPrefix}${userData?.profile?.imagePath}`}
            // eslint-disable-next-line no-nested-ternary
            alt={isPropData ? (data.profile?.imagePath ? '' : searchUser) : (userData?.profile?.imagePath ? '' : altText)}
          />
          {/* {loading ? (
            <CircularProgress size={20} />
          ) : ( */}
          <UserProfileContent className="user-profile-container-content">
            <div className="user-content">
              <span className="user-profile-name">{isPropData ? `${data?.firstName} ${data?.lastName}` : `${userData?.firstName} ${userData?.lastName}`}</span>
              <span className="user-profile-employee-id">{isPropData ? data?.employeeID : userData?.employeeID}</span>
            </div>
            <p className="user-profile-email">{isPropData ? data?.email : userData?.email}</p>
            <span className="user-profile-designation">
              {isPropData ? data?.profile?.designation : userData?.profile?.designation}
              {' '}
              (
              {isPropData ? data?.profile?.department : userData?.profile?.department}
              )
            </span>
            <p className="user-profile-number">
              {isPropData ? `+91${data?.personalDetails?.phoneNumber}` : `+91${userData?.personalDetails?.phoneNumber}`}
            </p>
          </UserProfileContent>
          {/* )} */}
        </UserProfileContainer>
      ) : <CircularProgress size={20} />}
    </UserProfileWrapper>
  );
};

export default UserProfiles;
