/* eslint-disable */
import React, { useState } from 'react';
// import styled from 'styled-components';
import {
  AppBar, Box, IconButton, Typography, Avatar, MenuItem, Menu, ListItemIcon, Grid,
} from '@mui/material';
import { Person, Logout } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { Image, Loader } from '../components';
import { config } from '../config';
import SearchBar from '../modules/Profile/components/SearchBar';
import { auth } from '../firebase';
import { logout } from '../store/actions';
import AppraisalsCycleMenu from '../modules/Profile/components/AppraisalMenu';
import Notification from '../components/Notification/Notification';
import ConfirmationModal from './LogOutConfirmationModal';
import { checkPermissions } from '../utils/roles/permissionUtils';

const Header: React.FC = () => {
  const user = useSelector((state: any) => state.user.employee);
  const { firstName, lastName, profile } = user;
  const fullName = `${firstName}`;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const imageUrlPrefix = `${config.profileImageUrl}/200x200/`;
  const dispatch = useDispatch();
  const altText = firstName && lastName ? `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}` : null;
  const navigate = useNavigate();
  const hasPermission = checkPermissions('PMSMYPROFILE', ['Read', 'Edit', 'Write', 'Delete']);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    handleClose();
    setShowConfirmation(true);
  };
  const handleConfirmLogout = () => {
    dispatch(logout());
    localStorage.clear();
    signOut(auth);
    setShowConfirmation(false);
    navigate("/login");
  };
  const handleModalClose = () => {
    setShowConfirmation(false);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#fff',
        padding: '10px',
        boxShadow: 'none',
        borderBottom: '1px solid #EEEEEE',
      }}
      className="headerContainer"
    >
      <Grid container alignItems="center">
        <Grid item xs={6} sm={4} md={3}>
          <Box
            sx={{
              width: 'auto',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/');
            }}
          >
            <Image src="/image 5.png" alt="Logo" />
          </Box>
        </Grid>

        <Grid item xs={6} sm={8} md={9} container justifyContent="flex-end" alignItems="center">
          <Box sx={{ display: { xs: 'none', sm: 'block' }, mr: 2 }}>
            <AppraisalsCycleMenu />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, mr: 2 }}>
            <SearchBar />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, mr: 3 }}>
            <Notification />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={profile?.imagePath ? '' : altText} src={`${imageUrlPrefix}${profile?.imagePath}`} />
            <Box sx={{ paddingLeft: 1, display: { xs: 'none', md: 'block' } }}>
              {fullName && profile?.department ? (
                <>
                  <Typography
                    sx={{
                      color: '#000000',
                      fontWeight: 900,
                      lineHeight: '19px',
                      fontFamily: 'Lato',
                    }}
                  >
                    {fullName}
                  </Typography>
                  <Typography sx={{ color: '#2559C3' }}>{profile?.department}</Typography>
                </>
              ) : (
                <Loader />
              )}
            </Box>
            <IconButton size="large" edge="end" aria-label="account of current user" aria-haspopup="true" color="inherit" onClick={handleClick}>
              {open ? <ExpandLessIcon style={{ color: '#2559C3' }} /> : <ExpandMoreIcon style={{ color: '#2559C3' }} />}
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {hasPermission && (
                <MenuItem component={Link} to="/profile" onClick={handleClose}>
                <ListItemIcon>
                  <Person fontSize="small" />
                </ListItemIcon>
                My profile
              </MenuItem>
              )}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
            <ConfirmationModal open={showConfirmation} onClose={handleModalClose} onConfirmLogout={handleConfirmLogout} />
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  );
};
export default Header;
