export enum Gender {
  MALE = 1,
  FEMALE,
}

interface PersonalDetails {
  phoneNumber: string;
  DOB: number
}

interface RM {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}
interface Profile {
  designation: string;
  businessUnit: string;
  department: string;
  location: string;
  managerID: RM;
  personalEmail: string;
  gender: number;
  imagePath?: string
}
export interface User {
  [x: string]: any;
  employeeID: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  profile: Profile;
  currentBURank: number;
  currentCompanyRank: number;
  pendingArtifact: number;
  totalArtifact: number;
  personalDetails: PersonalDetails;
}

export interface UserData {
  employeeID: string,
  firstName: string,
  lastName: string,
  email: string,
  profile: {
    designation: string,
    department: string,
    imagePath: string,
  },
  personalDetails: {
    phoneNumber: string,
  },
}
