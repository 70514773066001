/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Box, BoxProps } from '@mui/system';
import {
  Typography, Grid, Tooltip, CircularProgress,
} from '@mui/material';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import { BorderLinearProgress } from '../../Dashboard/components/BadgesAndTrophies/BadgesAndTrophies.styled';
import { truncateText } from '../../Artifact/components/helper';
import { getAuthHeader, getCurrentYear } from '../../../utils/utils';
import { StyledCustomizedWrapper2 } from '../../Dashboard/Dashboard.styled';

interface CategoryProps {
  categoryName: string;
  name: any;
  minimumScore: string;
  label: string;
  totalPoints: number;
  categoryPoints: number;
}

interface StyledScrollableBoxProps extends BoxProps {
  isProfileHeader?: boolean; // Optional prop
}

// Styled component for the scrollable box
const StyledScrollableBox = styled(Box)<StyledScrollableBoxProps>`
  overflow-y: auto;
  max-height: ${({ isProfileHeader }) => (isProfileHeader ? '143px' : '460px')};

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Height of the horizontal scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #6a5acd, #836fff); /* Gradient color */
    border-radius: 20px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #836fff, #6a5acd); /* Reverse gradient on hover */
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(to bottom, #f0f0f0, #e0e0e0); /* Track gradient */
    border-radius: 20px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #ccc #f0f0f0;
`;

const ProgressReport = ({ categoryDetails, hideTitle, isProfileHeader }) => {
  const [progressData, setProgressData] = useState<any[]>([]);
  const [check, setCheck] = useState(false);
  const loggedInUserId = localStorage.getItem('user_id');
  const [loading, setLoading] = useState<boolean>(true);
  const { userId } = useParams();
  const currentYear = (useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear())?.toString();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await CommHandler.request({
          url: `${config.apiBaseUrl}/artifactPoints/score?userId=${userId || loggedInUserId}`,
          method: 'GET',
          params: {
            year: currentYear,
            status: 1,
          },
          headers: getAuthHeader,
        });
        if (response?.status === 404) {
          setCheck(true);
        }
        if (response?.data) {
          setProgressData(response?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching rank:', error);
      } finally {
        // setLoading(false);
      }
    };
    fetchData();
  }, [loggedInUserId, userId, currentYear]);

  const category = (records: CategoryProps) => {
    const truncatedLabel = truncateText(
      check ? records?.name : records?.categoryName,
      8,
    );

    const percentage = (records?.categoryPoints > Number(records?.minimumScore)
      ? Number(records?.minimumScore)
      : (records?.categoryPoints ?? 0) / Number(records.minimumScore)) * 100 || 0;
    const checkPercentage = percentage > 100 ? 100 : Math.floor(percentage);
    return (
      <Box
        className="badge-strip-wrap"
        key={check ? records?.name : records?.label}
        sx={{
          display: 'flex',
          marginBottom: '10px',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tooltip
          title={check ? records?.name : records?.categoryName}
          arrow
        >
          <Box className="avatar-wrap">
            <Typography
              className="badge-icon-title"
              variant="body2"
              sx={{ marginRight: '12px', maxWidth: '95px' }}
            >
              {truncatedLabel}
            </Typography>
          </Box>
        </Tooltip>
        <Grid
          item
          xs
          className="borderLinearProgressWrapper"
        >
          <Grid
            container
            justifyContent="center"
          >
            <BorderLinearProgress
              variant="determinate"
              value={check ? 0 : checkPercentage}
              className="badge-progress-bar"
            />
          </Grid>
        </Grid>
        <Grid
          item
          style={{ minWidth: '135px', textAlign: 'left' }}
        >
          <Typography
            className="actual-points-text"
            variant="caption"
            style={{ margin: '0 4px' }}
          >
            {`${
              check
                ? 0
                : records?.categoryPoints
                > Number(records?.minimumScore)
                  ? Number(records?.minimumScore)
                  : records?.categoryPoints
            } / ${check ? 0 : records?.minimumScore} PTS `}
          </Typography>
          <Typography
            className="percentage-text"
            variant="caption"
            color="#7B7B7B"
          >
            {`${check ? 0 : checkPercentage}%`}
          </Typography>
        </Grid>
      </Box>
    );
  };

  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <StyledCustomizedWrapper2 className="dashbaodProfile">
        <Grid
          container
          direction="column"
          spacing={2}
          className="scoring-dash"
        >
          {hideTitle ? null : (
            <Grid style={{ width: '100%' }} item xs={12}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: '700',
                  color: '#06152B',
                }}
              >
                Progress Report
              </Typography>
            </Grid>
          )}
          <Grid style={{ width: '100%' }} item xs={12}>
            <StyledScrollableBox
              className="progress-bar-report"
              style={{
                overflowY: 'auto', maxHeight: isProfileHeader ? '143px' : '460px',
              }}
            >
              <Grid
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '24px',
                  // minWidth: '450px',
                  position: 'relative',
                }}

              >
                {loading ? (
                  <CircularProgress
                    style={{
                      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: 'auto',
                    }}
                    color="primary"
                  />
                ) : (
                  (check ? categoryDetails || [] : progressData || []).length === 0 ? (
                    <Typography variant="body1" color="textSecondary">
                      No data to display
                    </Typography>
                  ) : (
                    (check ? categoryDetails || [] : progressData || []).map(
                      (catname) => category(catname),
                    )
                  )
                )}
              </Grid>
            </StyledScrollableBox>
          </Grid>
        </Grid>
      </StyledCustomizedWrapper2>
    </Grid>
  );
};
export default ProgressReport;
