import styled from 'styled-components';
import { Divider } from '@mui/material';
const StyledUserDetailsWrapper = styled.div`
    grid-area: user;
    display: flex;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    margin: 98px 40px 28px 24px;
    width: auto;
`;
const StyledHomePageWrapper = styled.div`
    display: grid;
    grid-template-columns: 95px 1fr 1fr 100fr;
    grid-template-areas:
    "header header header header" 
    "nav  user   user   user  " 
    "nav  chart  chart  rank  " 
    "nav  chart1 chart2 rank  ";
    background: linear-gradient(0deg, #EFF1F8 0%, #FAFAFA 100%);
    Header {
        grid-area: header;
        position: relative important;
    }
    .side-bar-container {
        grid-area: nav;
    }
`;
const StyledLiveRankContainer = styled.div`
    &.live-ranking-container {
        grid-area: rank;
    }
`;
const StyledScoreBadgeContainer = styled.div`
    grid-area: chart;
    grid-colum: 2/4;
    display: flex;
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    margin: 0px 16px 16px 24px;
`;

const StyledGrowthContainer = styled.div`
    grid-area: chart1;
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    margin: 0px 16px 16px 24px;
    padding: 16px 16px 32px 16px;
`;
const StyledStreakContainer = styled.div`
    grid-area: chart2;
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    margin: 0px 16px 16px 0px;
    padding: 16px 16px 32px 16px;
`;

const StyledCustomizedDivider = styled(Divider)`
   border: 1px solid #E7E7E7 !important;
   flex:auto;
   flex-grow: 0;
`;
const StyledCustomizedDivider2 = styled(Divider)`
   border: 2px solid #DDDDDD !important;
   flex: auto;
   margin-top: 5vh !important;
   margin-bottom: 5vh !important;
   flex-grow: 0;
`;
const StyledCustomizedDivider3 = styled(Divider)`
   border: 1px solid #E7E7E7 !important;
   flex:1;
   margin: 0 16px !important;
   margin-top: 1vh !important;
   margin-bottom: 1vh !important;
   flex: auto;
`;
const StyledScoringUIContainer = styled.div`
    min-width: 288px;
    flex: 1 0 38%;
`;

const StyledCustomizedWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
`;

const StyledCustomizedWrapper1 = styled.div`
    margin-top: 24px;
    .custom-grid {
        @media (max-width: 1200px) {
            // padding-top: 20px;
        }
    }
`;

const StyledCustomizedWrapper2 = styled.div`
        display: flex;
        background: #fff;
        width: 100%;
        // max-width: 428px;
        margin-bottom: 0px;
        padding: 16px;
        border: 1px solid #E7E7E7;
        border-radius: 10px;
        @media (max-width: 1200px) {
            display: flex;
            flex-wrap: wrap;
            background: #fff;
            margin-bottom: 20px;
            padding: 16px;
            border: 1px solid #E7E7E7;
            border-radius: 10px;
        }
`;
const StyledBadgeContainer = styled.div`
    &.badge-container {
        padding: 16px 16px 16px 0px;
        min-width: 428px;
        flex: 1 0 57%;
        .menu-aligned {
            float: right;
            margin-right: -10px;
            .MuiSvgIcon-root {
                fill: #D9D9D9;
            }
        }
    }
`;

export {
  StyledUserDetailsWrapper, StyledHomePageWrapper,
  StyledScoreBadgeContainer, StyledGrowthContainer,
  StyledStreakContainer, StyledScoringUIContainer,
  StyledCustomizedDivider, StyledCustomizedDivider2,
  StyledLiveRankContainer, StyledCustomizedDivider3,
  StyledBadgeContainer, StyledCustomizedWrapper, StyledCustomizedWrapper1,
  StyledCustomizedWrapper2,
};

