/* eslint-disable */
import { Navigate, Outlet } from 'react-router-dom';
import { checkPermissions } from '../../utils/roles/permissionUtils';

interface ProtectedRouteProps {
  code: string;
  actionTypes?: string[]; // Optional, defaults to ['Read', 'Write', 'Edit', 'Delete']
}

const ProtectedRoute: React.FC<any> = ({ code, actionTypes = ['Read', 'Write', 'Edit', 'Delete'] }: ProtectedRouteProps) => {

  return checkPermissions(code, actionTypes, true) ? <Outlet /> : <Navigate to="/not-authorized" />;

};

export default ProtectedRoute;
