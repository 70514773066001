import React from 'react';
// import styled from 'styled-components';
import {
  Box,
  Typography,
  Modal,
  Button,
} from '@mui/material';

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmLogout: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirmLogout,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="logout-confirmation-modal"
    aria-describedby="logout-confirmation-modal"
  >
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 296,
        bgcolor: 'background.paper',
        borderRadius: 3,
        p: 4,
      }}
    >
      <Typography
        variant="h6"
        id="logout-confirmation-modal"
        component="div"
        align="center"
        fontWeight="600"
        color="#000000"
      >
        Are you sure you want to logout?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
          gap: '8px',
        }}
      >
        <Button
          variant="outlined"
          sx={{
            mr: 1,
            backgroundColor: 'white',
            textTransform: 'none',
            color: '#2559C3',
            borderColor: '#2559C3',
          }}
          onClick={onClose}
        >
          Go back
        </Button>
        <Button
          variant="contained"
          sx={{
            mr: 1,
            backgroundColor: '#2559C3',
            textTransform: 'none',
            color: '#fff',
          }}
          onClick={onConfirmLogout}
        >
          Logout
        </Button>
      </Box>
    </Box>
  </Modal>
);

export default ConfirmationModal;

