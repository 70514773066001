import React, { useEffect } from 'react';
import {
  Grid, Paper, Typography, Avatar, Box,
} from '@mui/material';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import { useDispatch, useSelector } from 'react-redux';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import { fetchUserKRA } from '../KRASlice';
import { RootState } from '../../../store/type';
import { User } from '../../Dashboard/components/UserProfile/type';
import { Kra } from '../type';
import { KeyResponsibilityWrapper } from './KeyResponsibility.styled';
import { getAuthHeader } from '../../../utils/utils';
// import { CustomeAvatar } from '../../../components';

const KeyResponsibility = () => {
  const dispatch = useDispatch();
  const selectUser = (state: RootState) => state.user.employee;
  const user: User = useSelector(selectUser);
  const { profile } = user ?? {}; // get User desiganation
  const userkra:string[] = useSelector((state:RootState) => state.KRA.responsibilities); // get user KRA from store
  const responsibilities = userkra ?? [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CommHandler.request({
          url: `${config.adminApiURL}/kra/designation/${profile?.designation}`,
          method: 'GET',
          headers: getAuthHeader,
        });
        const kra = response.data.map((item:Kra) => item.responsibilities);
        dispatch((fetchUserKRA(kra)));
        return response.data;
      } catch (err) {
        return false;
      }
    };

    fetchData();
  }, [dispatch, profile]);
  // remove HTML tags from a string
  function removeHtmlTags(input:any) {
    return input.replace(/<[^>]+>/g, ''); // This regex removes HTML tags
  }

  return (
    <KeyResponsibilityWrapper>
      <Paper className="key-responsibility-paper-wrap">
        <Grid item xs={12} className="key-title-wrap">
          <Typography className="key-title-text" variant="h5" gutterBottom>
            Key Responsibility Areas
          </Typography>
        </Grid>
        <Grid container spacing={2} className="key-container-wrap">

          {' '}
          {responsibilities && responsibilities.length ? (
            responsibilities.map((key) => (
              <Grid item xs={12} key={key} className="key-response-wrapper">
                <Box className="key-icon-text-wrapper" gap={1}>
                  <Avatar className="key-icon-wrap">
                    <OnDeviceTrainingIcon className="key-icon" />
                  </Avatar>
                  <Typography
                    variant="body2"
                    className="key-data-text"
                    gutterBottom
                  >
                    {removeHtmlTags(key)}
                  </Typography>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography
              gutterBottom
              align="center"
              className="key-no-data-text"
            >
              No data to display
            </Typography>
          )}

        </Grid>
      </Paper>
    </KeyResponsibilityWrapper>
  );
};

export default KeyResponsibility;
