/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import Typography from '../../../components/Typography/Typography';
import { RootState } from '../../../store/type';
import { Gender, User } from '../../Dashboard/components/UserProfile/type';
import { Loader } from '../../../components';
import { ProfileDetailWrapper } from './ProfileDetail.styled';
import { checkPermissions } from '../../../utils/roles/permissionUtils';

const ProfileDetail = (props:any) => {
  const { data } = props;
  const selectUser = (state: RootState) => state.user.employee;
  const user: User = useSelector(selectUser);
  const [userDetails, setUserDetails] = useState<any>({});
  const hasPrimaryPermission = checkPermissions('PMSMYPROFILE:SUBPRIMARYDET', ['Read', 'Edit', 'Write', 'Delete'], true);
  const hasContactPermission = checkPermissions('PMSMYPROFILE:SUBCONTACTDET', ['Read', 'Edit', 'Write', 'Delete'], true);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      if (user.firstName === data.firstName && user.middleName === data.middleName && user.lastName === data.lastName) {
        setUserDetails(user);
      } else {
        setUserDetails(data);
      }
    } else {
      setUserDetails(user);
    }
  }, [user, data]);
  return (
    <ProfileDetailWrapper>
      <Grid container item spacing={2} className="profile-detail-parent-grid">
      {hasPrimaryPermission && (
        <Grid container item xs={12} md={6}>
          {Object.keys(user).length ? (
            <Paper className="details-paper-wrap">
              
                <Grid container item>
                  <Typography variant="h4" gutterBottom>
                    Primary Details
                  </Typography>
                </Grid>
              
              <Grid container item rowGap={1} mt={2}>
                <Grid container>
                  <Grid item xs={4}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          First Name
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                          {userDetails?.firstName}
                          {' '}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          Middle Name
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                          {userDetails?.middleName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          Last Name
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                          {userDetails?.lastName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          Display Name
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                          {`${userDetails?.firstName} ${userDetails?.lastName}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          Gender
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                          {userDetails?.profile?.gender === Gender.MALE ? 'Male' : 'Female'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={4}>
                        <Grid container direction="column">
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              gutterBottom
                            >
                              Date Of Birth
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              gutterBottom
                            >
                              { userDetails ? moment(userDetails?.profile?.personalDetails?.DOB).format('D MMM YYYY')
                                : moment(userDetails.profile.personalDetails?.DOB).format('D MMM YYYY')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid> */}
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <Loader />
          )}
        </Grid>
      )}
      {hasContactPermission && (
        <Grid container item xs={12} md={6}>
          <Paper className="details-paper-wrap">
            
              <Grid container item>
                <Typography variant="h3" gutterBottom>
                  Contact Details
                </Typography>
              </Grid>
          
            <Grid container item rowGap={1} mt={2}>
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant="body2" gutterBottom>
                  Work Email
                </Typography>
                <Typography variant="body1" gutterBottom className="email-text">
                  {userDetails?.email}
                </Typography>
              </Grid>
              {/* <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="body2"
                  gutterBottom
                >
                  Personal Email

                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className="email-text"
                >
                  {userDetails.profile?.personalEmail}
                </Typography>
              </Grid> */}
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant="body2" gutterBottom>
                  Contact Number
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.personalDetails?.phoneNumber}
                </Typography>
              </Grid>
              {/* <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="body2"
                  gutterBottom
                >
                  Whatsapp Number

                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                >
                  {userDetails?.profile?.personalDetails?.phoneNumber}
                </Typography>
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>
        )}
      </Grid>
    </ProfileDetailWrapper>
  );
};
export default ProfileDetail;
