import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Loader } from '../components';
import { auth } from '../firebase';
import { authorizedPath } from '../libs/Constant';

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position:fixed;
  z-index:99999;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color: rgba(16, 16, 16, 0.5);
  transition: 1s 0.4s;
`;

const AuthRoute: any = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const loggedUser = useSelector((state: any) => state.user.employee);

  if (!loggedUser?.isSuperAdmin && authorizedPath.SUPERADMIN.includes(location.pathname)) {
    navigate('/');
  }

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      const isValidEmail = (user || {}).email?.endsWith('@successive.tech');
      setLoading(false);
      if (!isValidEmail) {
        localStorage.clear();
        navigate('/login');
      }
      auth?.currentUser?.getIdToken().then((idToken) => {
        const oldToken = localStorage.getItem('token');
        if (!oldToken || (oldToken && oldToken !== idToken)) {
          localStorage.setItem('token', idToken);
        }
      }).catch((error) => (
        // eslint-disable-next-line no-console
        console.error(error)
      ));
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <StyledLoader><Loader /></StyledLoader>;
  }

  return <Outlet />;
};

export default AuthRoute;
