import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Select, styled } from '@mui/material';
import { storeAppraisalCycleSelected } from './AppraisalCycleSlice';
import { getCurrentYear } from '../../../utils/utils';

interface AppraisalCycle {
  duration: string;
  label?: string;
}

const StyledMenuSelect = styled(Select)`
  &.menu-item-list {
    color: #848282;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;    
  }
`;

const AppraisalsCycleMenu: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState(getCurrentYear());
  const [appraisalOptions, setAppraisalOptions] = useState([getCurrentYear()]);
  const dispatch = useDispatch();
  const joiningYear = useSelector(
    (state: any) => state.appraisalCycle.joiningYear,
  );

  const currentYear = selectedOption ? parseInt(selectedOption.toString(), 10) : getCurrentYear();
  const nextYear = parseInt(selectedOption.toString(), 10) + 1 || currentYear + 1;

  const [appraisalCycle, setAppraisalCycle] = useState<AppraisalCycle>({
    duration: `01 April, ${currentYear} to 31 March, ${nextYear}`,
  });

  const appraisalOptionsfun = (joiningVal:Date) => {
    const options = [];
    const startyear = joiningVal.getMonth() > 2 ? joiningVal.getFullYear() : joiningVal.getFullYear() - 1;
    for (let year = startyear; year <= getCurrentYear(); year += 1) {
      options.push(year);
    }
    return options;
  };

  useEffect(() => {
    const fetchAppraisalCycle = async () => {
      const appLunchDate = new Date('2023-04-01');
      const empJoining = new Date(joiningYear || appLunchDate);
      const startingDate = appLunchDate.getFullYear() >= empJoining.getFullYear()
        ? appLunchDate
        : empJoining;
      setAppraisalOptions(appraisalOptionsfun(startingDate));
    };

    fetchAppraisalCycle();
  }, [joiningYear]);

  useEffect(() => {
    dispatch(storeAppraisalCycleSelected(selectedOption || getCurrentYear()));
  }, [dispatch, selectedOption]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    const nextYearValue = parseInt(option, 10) + 1;
    setAppraisalCycle({
      duration: `01 April, ${option} to 31 March, ${nextYearValue}`,
    });
  };

  return (
    <div>
      {appraisalCycle && (
        <StyledMenuSelect
          className="menu-item-list"
          value={selectedOption}
          sx={{
            boxShadow: 'none',
            marginRight: '10px',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          }}
          placeholder="name"
        >
          {appraisalOptions.map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={() => handleOptionSelect(option)}
            >
              Financial Year
              {' '}
              {option}
              -
              {`${option + 1}`.slice(-2)}
            </MenuItem>
          ))}
        </StyledMenuSelect>
      )}
    </div>
  );
};

export default AppraisalsCycleMenu;
