/* eslint-disable react/no-this-in-sfc */
import React, { useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { LineChartOptions } from './types';

const LineChart = ({
  title,
  xAxis,
  yAxis,
  data,
  modifiedTooltip,
}: LineChartOptions) => {
  const [tickInterval, setTickInterval] = React.useState(10);

  useEffect(() => {
    const dataMin = Math.min(...data);
    const dataMax = Math.max(...data);
    const interval = Math.ceil((dataMax - dataMin) / 10);
    const roundedTickInterval = Math.ceil(interval / 10) * 10;

    setTickInterval(roundedTickInterval);
  }, [data]);

  const options: Highcharts.Options = {
    chart: {
      height: 390,
      width: 490,
      style: {
        fontFamily: 'Inter',
        fontSize: '14.137px',
        fontWeight: '500',
        color: '#838383',
      },
    },
    tooltip: modifiedTooltip
      ? {
        formatter() {
          const xValue = xAxis?.categories[this.point.index];
          const yValue = this.y < 0 || this.y === null ? 0 : this.y;
          const pointText = yValue === 0 || yValue === 1 ? 'Point' : 'Points';
          return `${xValue}: ${yValue} ${pointText}`;
        },
      }
      : {},
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: title,
    },
    xAxis: {
      labels: {
        style: {
          color: '#838383',
          textAlign: 'center',
          fontFamily: 'Inter',
          fontSize: '14.137px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
        },
      },
      categories: xAxis?.categories,
    },
    yAxis: {
      labels: {
        style: {
          color: '#000',
          textAlign: 'right',
          fontFamily: 'Inter',
          fontSize: '15.309px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '19.811px',
        },
      },
      title: {
        text: yAxis?.title?.text,
      },
      tickInterval,
    },
    series: [
      {
        type: 'line',
        name: '',
        data: data?.map((value, index) => [xAxis?.categories[index], value]) || [],
        color: '#2559C3',
        marker: {
          fillColor: '#FFFFFF',
          lineWidth: 2,
          lineColor: '#2559C3', // inherit from series
        },
      },
    ],
  };

  return (
    <div className="score-chart-inner-wrap">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

LineChart.defaultProps = {
  xAxis: {
    categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
  },
  yAxis: {
    title: {
      text: 'Value',
    },
  },
  data: [1, 3, 2, 4, 5, 7, 6, 8, 9, 10, 8, 7],
};

export default LineChart;
